import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

ReactDOM.render(
	// Todo: maybe React.StrictMode?
	<App />,
	document.getElementById("root")
);
