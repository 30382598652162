import firebase from 'firebase/app';
const firebaseConfig = {
    apiKey: "AIzaSyA8rvZL4AUy4IjpirHFQi3TXW3A2qnQlCs",
    authDomain: "culture-blog-84dd9.firebaseapp.com",
    databaseURL: "https://culture-blog-84dd9.firebaseio.com",
    projectId: "culture-blog-84dd9",
    storageBucket: "culture-blog-84dd9.appspot.com",
    messagingSenderId: "916321260946",
    appId: "1:916321260946:web:335ea4329bb8ae79841d4b",
    measurementId: "G-XZV7VL22YD"
  };
firebase.initializeApp(firebaseConfig);
export default firebase;
